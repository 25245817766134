import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <>
      <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
        <p className="text-sm text-designColor tracking-[4px]">2023 - 2024</p>
        <h2 className="text-3xl md:text-4xl font-bold">
          Proficient in software and Learner
        </h2>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
      >
        {/* part one */}
        <div>
          <div className="mt-6 lgl:mt-14 w-full h-auto border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
            <ResumeCard
              title="Full Stack Developer"
              subTitle="The Hacking School (2023 - 2024)"
              result=""
              des="I have 1 year of bootcamp experience as a Fullstack Developer with a certificate."
            />
          </div>
        </div>
        {/* part Two */}

        <div>
          <div className="mt-6 lgl:mt-14 w-full h-auto border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10 ">
            <ResumeCard
              title="FullStack Developer specialized  in MERN"
              subTitle="The Hacking School (2023 - 2024)"
              result=""
              des="I am an experienced Fullstack Developer with expertise in the MERN stack, web design, web development,UI/UX, and hosting. My skills include HTML, CSS, JavaScript, React, Node.js, Express, and MongoDB. I have also completed several projects. "
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Experience;
