import React from "react";
import Title from "../layout/Title";
import ProjectsCard from "./ProjectsCard";
import { portfolio,task,booking,ecommerce,food,github,hospital,joke,Weather } from "../../assets/index";
const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex  justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid  grid-cols-2  md:grid-cols-1 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Hospial Management System"
          des=" The Hospital Management System is a comprehensive MERN stack application designed with three roles: Dean, Doctor, Receptionis and Patient. Patients can conveniently book appointments, while the Dean can manage Doctors and Receptionists through full CRUD operations, ensuring efficient administration and streamlined healthcare services.Like that doctor can do same curd operation with receptionist ,Receptionist can do the curd operation with patients"
          src={hospital}
          link='https://github.com/rajaramsahoo/HOSPITAl-APP'
        />
        <ProjectsCard
          title="E-commerce Website"
          des=" The eCommerce website, designed using the MERN stack project designed for both Admin and User roles, offers a seamless online shopping experience. It features product listings, a user-friendly shopping cart, secure payment integration, and robust user authentication, ensuring a comprehensive and efficient platform for both buyers and sellers."
          src={ecommerce}
          link='https://github.com/rajaramsahoo/eCommerce-APP'

        />
        <ProjectsCard
          title="Task Management"
          des=" The Task Management System is a robust MERN stack application that allows users to perform full CRUD operations on tasks. Users can create, read, update, and delete tasks, ensuring efficient task organization and streamlined project management."
          src={task}
          link='https://github.com/rajaramsahoo/Task-Management'

        />
        <ProjectsCard
          title="Hotel Booking App"
          des=" The Hotel Booking App is a sleek MERN stack application styled with Tailwind CSS, enabling users to browse and book hotels effortlessly. With a user-friendly interface, the app supports seamless room reservations and efficient booking management."
          src={booking}
          link='https://github.com/rajaramsahoo/BOOKING-APP'

        />
        <ProjectsCard
          title="Github Search Engine"
          des=" The GitHub Search App is a powerful tool designed to allow users to search for and explore GitHub accounts. Built entirely with React for the frontend, this app utilizes the GitHub API to fetch and display detailed information about GitHub users. The app offers a user-friendly interface, making it easy to search for any GitHub account and access comprehensive details about repositories, contributions, and more."
          src={github}
          link='https://github.com/rajaramsahoo/Github-search'

        />
        <ProjectsCard
          title="Weather App"
          des=" The Weather Report App is a dynamic application designed to provide detailed weather information. Built with React for the frontend and leveraging a weather API, this app allows users to search for and view current weather conditions and forecasts for any location. The app offers a user-friendly interface and comprehensive climate details including temperature, humidity, wind speed, and more."
          src={Weather}
          link='https://github.com/rajaramsahoo/Weather-App'

        />
        <ProjectsCard
          title="Jokes App"
          des="The Joke Finding App is a fun and engaging application designed to provide users with an endless supply of jokes. Built with React for the frontend and Axios for handling API requests, this app allows users to search for jokes and retrieve them from a jokes API. The app offers a user-friendly interface and an extensive database of jokes to keep users entertained."
          src={joke}
          link='git@github.com:rajaramsahoo/Jokes-app.git'

        />
         <ProjectsCard
          title="Food Ordering App"
          des=" The Food Ordering App is a dynamic MERN stack project designed for both Admin and User roles. Users can browse, order, and track their food orders, while Admins have the capability to manage the menu, process orders, and oversee customer interactions through full CRUD operations, ensuring a seamless food ordering experience."
          src={food}
          link='https://github.com/rajaramsahoo/FoodOrderingApp'

        />
         <ProjectsCard
          title="Portfolio"
          des="This portfolio showcases my skills and projects as a Fullstack Developer specializing in the MERN stack. It features a collection of my work, including dynamic applications, web designs, and other software solutions. My portfolio demonstrates my proficiency in React, Node.js, Express.js, and MongoDB, as well as my ability to create responsive and user-friendly web applications."
          src={portfolio}
        />
      </div>
    </section>
  );
};

export default Projects;
