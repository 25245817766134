import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";
const Education = () => {
  return (
    <>
      {" "}
      <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
        <p className="text-sm text-designColor tracking-[4px]">2003 - 2024</p>
        <h2 className="text-3xl md:text-4xl font-bold">
          Educational Qualification
        </h2>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
      >
        {/* part one */}

        <div>
          <div className="mt-6 lgl:mt-14 w-full h-auto border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
            <ResumeCard
              title="Full Stack Developer"
              subTitle="The Hacking School (2023 - 2024)"
              result=""
              des="Starting 2023, I enrolled to Fullstack development bootcamp by The Hacking School. After completion, I have been working on several projects & freelancing to improve my portfolio for a full time web developer."
            />

            <ResumeCard
              title="CHSE"
              subTitle="Gayatri +2 Science College (2013 - 2015)"
              result="65 %"
              des="I completed my class 12 at Gayatri +2 Science College , Bhubaneswar, where I studied Science with Biology.."
            />
          </div>
        </div>
        {/* part Two */}

        <div>
          <div className="mt-6 lgl:mt-14 w-full h-auto border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
            <ResumeCard
              title="BTech in Mechanical Enginnering"
              subTitle="Gandhi Insitute For Technology (2015 - 2019)"
              result="7.12 CGPA"
              des="I have completed Bachelor's degree in Mechanical at Gandhi Institute For Technology, Bhubaneswar."
            />

            <ResumeCard
              title="Board Of Secondary Education"
              subTitle="Kosala High School (2003 - 2013)"
              result="75 %"
              des="I completed my class 10 education at Kosala High School, Angul, where I studied MIL, Math, Emglish, Social Science, Sanskrit, History and Geography."
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Education;
