import React from "react";
import Media from "./Media";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Professional Coder.", "Full Stack Developer specialize in MERN .", "UI Designer."],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-5xl font-bold text-white">
          Hi, I'm{" "}
          <span className="text-designColor capitalize">Rajaram Sahoo</span>
        </h1>
        <h2 className="text-3xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="aqua"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        I'm a full-stack developer specializing in the MERN stack, with almost 2 years of experience in web and software development. I graduated from The Hacking School, Hyderabad web development bootcamp in 2023. Highly motivated and passionate about learning new technologies, I strive to deliver high-quality work and am actively seeking opportunities in the IT domain.        </p>
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default LeftBanner;
