import { AiFillAppstore } from "react-icons/ai";
import { SiProgress } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Full Stack Developement",
    des: "As a Fullstack Developer, I bridge the gap between front-end and back-end development, creating seamless and efficient web applications. My expertise in both client-side and server-side technologies enables me to build robust, scalable, and user-friendly solutions. From designing dynamic user interfaces to implementing complex server logic, I ensure a cohesive and comprehensive approach to web development.",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Frontend Developement",
    des: "With a passion for crafting beautiful and intuitive user experiences, I specialize in Frontend Development. I bring designs to life using modern technologies like React, ensuring responsive and interactive interfaces. My focus is on creating clean, maintainable, and accessible code, making the web a more enjoyable place for users. I thrive on pushing the boundaries of design and functionality to deliver engaging digital experiences.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "Backend Developement",
    des: "As a Backend Developer, I focus on the server-side logic, database management, and API integration that power web applications. My skills in Node.js, Express, and MongoDB allow me to build secure, efficient, and scalable backend systems. I ensure that data flows smoothly and securely, providing the necessary infrastructure for frontend applications to thrive. My goal is to create robust backend solutions that meet the needs of modern web applications.",
  },
];
