import React from "react";
import { motion } from "framer-motion";
import {
  FaGithub,
  FaAws,
  FaReact,
  FaNode,
  FaHtml5,
  FaCss3Alt,
  FaBootstrap,
  FaLinux,
} from "react-icons/fa";
import { SiTailwindcss, SiExpress, SiMongodb, SiPostman } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { DiMysql } from "react-icons/di";
import { TbBrandRedux } from "react-icons/tb";

const Skills = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Skills and Expertise on
          </p>
          <h2 className="text-3xl md:text-4xl font-bold ">Frontend</h2>
        </div>
        <div className='className="mt-14 w-full flex flex-col gap-6'>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">HTML</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaHtml5 />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">css</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaCss3Alt />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">javascript</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <IoLogoJavascript />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">React js</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaReact />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Redux</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <TbBrandRedux />
                </span>
              </motion.span>
            </span>
          </div>
        </div>
      </div>

      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Skills and Expertise on
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Backend</h2>
        </div>
        <div className="flex flex-col gap-6">
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Node Js</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaNode />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden ">
            <p className="text-sm uppercase font-medium">Express Js</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <SiExpress />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Mongo Db</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <SiMongodb />
                </span>
              </motion.span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Proficient In
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Knowledge</h2>
        </div>
        <div className="flex flex-col gap-6 ">
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Tailwind css</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <SiTailwindcss />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Scss</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaCss3Alt />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Bootstarp</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaBootstrap />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Aws</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaAws />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Git & Github</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaGithub />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">My Sql</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <DiMysql />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Linux</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <FaLinux />
                </span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Postman</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r from-[#a0e7e5] via-[#00ffff] to-[#0c747b] rounded-md relative"
              >
                <span className="absolute -top-7 right-0">
                  <SiPostman />
                </span>
              </motion.span>
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Skills;
