import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaGithub,
  FaAws,
  FaLinkedinIn,
  FaReact,
  FaNode,
  FaHtml5,
  FaCss3Alt,
  FaBootstrap,
  FaDownload,
} from "react-icons/fa";
import { SiTailwindcss, SiExpress, SiMongodb } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { DiMysql } from "react-icons/di";
import { TbBrandRedux } from "react-icons/tb";
const Media = () => {


  return (
    <div className="grid flex-col xl:flex-row gap-6 lgl:gap-6 justify-between">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4"> Resume</h2>
        <div className="flex gap-4">
          <a href="rajaram.pdf" target="_blank" >
          <span className="bannerIcon" title="Download Resume"  >
            <FaDownload />
          </span>
          </a>
          
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          <a href="https://www.facebook.com/rajaram.sahoo.35" target="_blank" rel="noreferrer">
            <span className="bannerIcon" title="facebook">
              <FaFacebookF />
            </span>
          </a>
          <a href="https://www.instagram.com/rajaram_raza/" target="_blank" rel="noreferrer">
            <span className="bannerIcon" title="Twitter">
              <FaInstagram />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/in/rajaram-sahoo-658a63227/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="bannerIcon" title="Linkdin">
              <FaLinkedinIn />
            </span>
          </a>
          <a href="https://github.com/rajaramsahoo" target="_blank" rel="noreferrer">
            <span className="bannerIcon" title="Github">
              <FaGithub />
            </span>
          </a>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">
          BEST SKILL ON
        </h2>
        <div className="flex flex-wrap gap-4 sm:gap-4 md:gap-4 lg:gap-4">
          <span className="bannerIcon" title="HTML">
            <FaHtml5 />
          </span>{" "}
          <span className="bannerIcon" title="CSS">
            <FaCss3Alt />
          </span>{" "}
          <span className="bannerIcon" title="Java Script">
            <IoLogoJavascript />
          </span>
          <span className="bannerIcon" title="React JS">
            <FaReact />
          </span>
          <span className="bannerIcon" title="Node JS">
            <FaNode />
          </span>
          <span className="bannerIcon" title="Express">
            <SiExpress />
          </span>
          <span className="bannerIcon" title="Mongo DB">
            <SiMongodb />
          </span>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">KNOWLEDGE</h2>
        <div className="flex flex-wrap gap-4 sm:gap-4 md:gap-4 lg:gap-4">
          <span className="bannerIcon" title="Bpptstrap">
            <FaBootstrap />
          </span>{" "}
          <span className="bannerIcon" title="Tailwind CSS">
            <SiTailwindcss />
          </span>{" "}
          <span className="bannerIcon" title="AWS">
            <FaAws />
          </span>
          <span className="bannerIcon" title="My Sql">
            <DiMysql />
          </span>
          <span className="bannerIcon" title="Redux">
            <TbBrandRedux />
          </span>
          {/* <span className="bannerIcon">
              <SiExpress  /> 
            </span>
            <span className="bannerIcon">
              <SiMongodb /> 
            </span>
            <span className="bannerIcon">
              <SiTailwindcss />
            </span> */}
        </div>
      </div>
    </div>
  );
};

export default Media;
