
const FooterBottom = () => {
    return (
      <div className="w-full py-10">
        <p className="text-center text-gray-500 text-base ">
          © 2022. All rights reserved by Rajaram Sahoo
        </p>
      </div>
    );
  }
  
  export default FooterBottom